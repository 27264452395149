.update-wrapper {
  position: fixed;

  top: 0;

  left: 0;

  background: rgba(0, 0, 0, 0.7);

  height: 100%;

  width: 100%;

  z-index: 9999;
  display: none;
}

.update-wrapper .update-box {
  top: 50%;

  position: relative;

  left: 50%;

  width: 400px;

  margin-left: -200px;

  text-align: center;

  color: white;

  margin-top: -50px;
}
.update-wrapper .update-box h3 {
  font-weight: bold;
  color: white;
}

.update-wrapper .loader {
  margin: 0 auto;

  border: 3px solid greenyellow;

  border-right-color: greenyellow;
  border-left-color: greenyellow;

  border-left-color: transparent;

  border-right-color: transparent;
  height: 50px;

  width: 50px;
  border-radius: 60%;

  animation: cssload-spin 800ms infinite linear;

  -o-animation: cssload-spin 800ms infinite linear;

  -ms-animation: cssload-spin 800ms infinite linear;

  -webkit-animation: cssload-spin 800ms infinite linear;

  -moz-animation: cssload-spin 800ms infinite linear;
}
