/* * {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}
body {
  font-family: Arial;
  background-color: #3498db;
  padding: 50px;
} */
.login-form-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(250, 112, 53, 0.7);
}
.login-form-wrapper .login {
  margin: 20px auto;
  width: 350px;
  top: 50%;
  position: relative;
  margin-top: -165px;
}
.login-form-wrapper .login-screen {
  background-color: #fff;
  padding: 50px;
  border-radius: 5px;
}

.login-form-wrapper .app-title {
  text-align: center;
  color: #777;
}

.login-form-wrapper .login-form {
  text-align: center;
}
.login-form-wrapper .control-group {
  margin-bottom: 10px;
}

.login-form-wrapper input {
  text-align: center;
  background-color: #ecf0f1;
  border: 2px solid transparent;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 200;
  padding: 10px 0;
  width: 250px;
  transition: border 0.5s;
}

.login-form-wrapper input:focus {
  border: 2px solid #bf3a14;
  box-shadow: none;
}

.login-form-wrapper .btn {
  border: 2px solid transparent;
  background: #fa7035;
  color: #ffffff;
  font-size: 16px;
  line-height: 25px;
  padding: 10px 0;
  text-decoration: none;
  text-shadow: none;
  border-radius: 3px;
  box-shadow: none;
  transition: 0.25s;
  display: block;
  width: 250px;
  margin: 0 auto;
}

.login-form-wrapper .btn:hover {
  background-color: #bf3a14;
}

.login-form-wrapper .login-link {
  font-size: 12px;
  color: #444;
  display: block;
  margin-top: 12px;
}

.bg-animated {
  background-image: url("/static_dash/images/end-user.png");
  background-position: 0 0;
  background-repeat: repeat;
  -webkit-animation: sidedownscroll 30s linear infinite;
  animation: sidedownscroll 30s linear infinite;
  background-size: auto;
  background-position: 0 0;
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  box-sizing: border-box;
  will-change: background-position;
}

@-webkit-keyframes sidedownscroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -2000px 2000px;
  }
}

@keyframes sidedownscroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -2000px 2000px;
  }
}

.loading-bar {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 24px;
}
